/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react"
import { MDBDataTable,MDBRow,MDBBtn,MDBIcon } from "mdbreact"
import { Row, Col, Card, Button,
    
  Modal,
  ModalBody,
  ModalHeader,Progress,
  CardBody, CardTitle, CardSubtitle } from "reactstrap"
  import { AvField, AvForm } from "availity-reactstrap-validation"
  import addnewAdminUser,{ deleteAdmob,columns, updateAdminUser} from "./common"
  import {db,check_login} from '../../Firebase/FIREBASE_CONFIG'
//Import Breadcrumb
import { query, collection, getDocs, where } from "firebase/firestore";

import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Tables/datatables.scss"
import './users.css'
import SkeletonElement from "../../skeletons/SkeletonElement"
const Users  = props => {
  
  check_login() 
  const [modalcategory,setModalcategory]=useState(false)
  const [subscriberData,setSubscriberData]=useState(false)
  const [event, setEvent] = useState({})
  const [hashedPassword, setHashedPassword]=useState()
  const [tableData, setTableData] = useState([])
  const [isCreate, setisCreate] = useState()
  const getAdminUsers=()=>{
   
    db.collection('admin-users')
    .get()
    .then(querySnapshot => {
      const updatedSubscriptionItems = 
                  querySnapshot.docs.map((docSnapshot) => ({
                    sub_id:docSnapshot.id,
                    username:docSnapshot.data().username,
                    email: docSnapshot.data().email}))
                  updateAdMobs(updatedSubscriptionItems)
     
    });
    
   
  }
  
  const updateAdMobs=(info)=>{
    let userData = [];
    info.map((item, index) => {
      item.id = (
        <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.name}</div>
      );
      item.action = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
           <div className="uil-edit-alt"
           onClick={(event => toggleCategory(event,false,item))}><i className="mdi mdi-square-edit-outline"></i></div>
           <div className="uil-trash-alt"><i className="mdi mdi-trash-can"></i></div>
        </div>
      );
      userData.push(item);
    });
    setTableData(userData)
  }
  useEffect(() => {
    getAdminUsers()
  },[]);
  const data={columns:columns,rows:tableData}
  const toggleCategory = (event,iscreate,item) => {
    event.preventDefault()
    if(iscreate){
      setSubscriberData()
      setisCreate(iscreate)
      setModalcategory(!modalcategory)
    }else{
      setSubscriberData(item)
      setisCreate(iscreate)
      setModalcategory(!modalcategory)
    }
     
    };
    const handleValidEventSubmitcategory = (event, values) => {
      const newdata = {
        username: values["username"],
        email: values["email"]
      }
      const authdata={
        email: values["email"],
        password: values["password"]
      }
    {!isCreate && (newdata['id']=subscriberData['sub_id'])}
    // save new event
    {!isCreate && updateAdminUser(newdata)}
    //addnewAds(newdata)
    {isCreate && addnewAdminUser(newdata,authdata)}
      
      getAdminUsers()
      toggleCategory(event)
    }
    const delete_adMob=(event,id)=>{
      event.preventDefault();
      deleteAdmob(id)
      getAdminUsers()
    }
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Admin Users" breadcrumbItem="Users" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Admin Users Datatable </CardTitle>
                  <div className="subscriber__button">
                      <Button
                        color="primary"
                        onClick={(event => toggleCategory(event,true))}
                        className="btn font-16 btn-primary w-100"
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                       Create Admin User
                      </Button></div>
                  
                      {tableData.length?<MDBDataTable responsive bordered data={data} />:
                <div className="">
                <SkeletonElement type={'title users'} size={3}/>
                 <SkeletonElement type={'title users'} size={3}/>
                 <SkeletonElement type={'title users'} size={3}/>
                 <SkeletonElement type={'title users'} size={3}/>
                 <SkeletonElement type={'title users'} size={3}/>
                 <SkeletonElement type={'title users'} size={3}/>
                 <SkeletonElement type={'title users'} size={3}/>
                 <SkeletonElement type={'title users'} size={3}/>
                 <SkeletonElement type={'title users'} size={3}/>
                 <SkeletonElement type={'title users'} size={3}/>
                 <SkeletonElement type={'title users'} size={3}/>
                </div>}
              </CardBody>
            </Card>
            <Modal
                isOpen={modalcategory}
                toggle={toggleCategory}
                className={props.className}
              >
                <ModalHeader toggle={toggleCategory} tag="h4">
                  Add Admin User
                        </ModalHeader>
                <ModalBody>
                  <AvForm
                    onValidSubmit={handleValidEventSubmitcategory}
                  >
                    <Row form>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="username"
                          label="Username"
                          type="text"
                          disabled={!isCreate}
                          errorMessage="Invalid username"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.username
                            :event.adMobType
                              ? event.adMobType
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="email"
                          label="User Email"
                          type="text"
                          disabled={!isCreate}
                          errorMessage="Invalid User email"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.email
                            :event.id
                              ? event.id
                              : ""
                          }
                        />
                      </Col>
                      {!isCreate?'':
                      <Col className="col-12 mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="text"
                          errorMessage="Invalid Password"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            event.id
                              ? event.id
                              : ""
                          }
                        />
                      </Col>
}
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-right">
                          <button
                            type="button"
                            className="btn btn-light me-2"
                            onClick={toggleCategory}
                          >
                            Close
                                  </button>
                          <button
                            type="submit"
                            className="btn btn-success save-event"
                          >
                            {!isCreate?'Update':'Save'}
                                  </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
          </Col>
        </Row>

       
      </div>

    </React.Fragment>
  )
}

export default Users
