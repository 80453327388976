import React, { useEffect, useState } from "react"
import { MDBDataTable,MDBRow,MDBBtn,MDBIcon } from "mdbreact"
import { Row, Col, Card, Button,
    
  Modal,
  ModalBody,
  ModalHeader,Progress,
  CardBody, CardTitle, CardSubtitle } from "reactstrap"
  import { AvField, AvForm } from "availity-reactstrap-validation"
  import addnewAdMobs,{ deleteAdmob,columns, updateAdMobs} from "./common"
  import {db,check_login} from '../../Firebase/FIREBASE_CONFIG'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Tables/datatables.scss"
import './adMob.css'
import SkeletonElement from "../../skeletons/SkeletonElement"
const AdMobs  = props => {
  
  check_login() 
  const [modalcategory,setModalcategory]=useState(false)
  const [subscriberData,setSubscriberData]=useState(false)
  const [event, setEvent] = useState({})
  const [hashedPassword, setHashedPassword]=useState()
  const [tableData, setTableData] = useState([])
  const [isCreate, setisCreate] = useState()
  const getAdMobs=()=>{
   
    db.collection('ad-mobs')
    .get()
    .then(querySnapshot => {
      const updatedSubscriptionItems = 
                  querySnapshot.docs.map((docSnapshot) => ({
                    admob_id:docSnapshot.data().id,
                    adMobType: docSnapshot.data().adMobType}))
                  updateAdMobs(updatedSubscriptionItems)
     
    });
    
   
  }
  
  const updateAdMobs=(info)=>{
    let userData = [];
    info.map((item, index) => {
      item.id = (
        <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.name}</div>
      );
      item.action = (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
           <div className="uil-edit-alt"
           onClick={(event => toggleCategory(event,false,item))}><i className="mdi mdi-square-edit-outline"></i></div>
           <div className="uil-trash-alt"
           onClick={(event => delete_adMob(event,item.sub_id))}><i className="mdi mdi-trash-can"></i></div>
        </div>
      );
      userData.push(item);
    });
    setTableData(userData)
  }
  useEffect(() => {
    getAdMobs()
  },[]);
  const data={columns:columns,rows:tableData}
  const toggleCategory = (event,iscreate,item) => {
    event.preventDefault()
    if(iscreate){
      setSubscriberData()
      setisCreate(iscreate)
      setModalcategory(!modalcategory)
    }else{
      setSubscriberData(item)
      setisCreate(iscreate)
      setModalcategory(!modalcategory)
    }
     
    };
    const handleValidEventSubmitcategory = (event, values) => {
      const newdata = {
        id: values["id"],
        adMobType: values["adMobType"]
      }
      // save new event
      addnewAdMobs(newdata)
      getAdMobs()
      toggleCategory(event)
    }
    const delete_adMob=(event,id)=>{
      event.preventDefault();
      deleteAdmob(id)
      getAdMobs()
    }
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Ad-Mobs" breadcrumbItem="Ad-Mobs" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>AdMob Datatable </CardTitle>
                  <div className="subscriber__button">
                      <Button
                        color="primary"
                        onClick={(event => toggleCategory(event,true))}
                        className="btn font-16 btn-primary w-100"
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                       Create Ad-Mobs
                      </Button></div>
                  
                      {tableData.length?<MDBDataTable responsive bordered data={data} />:
                <div className="">
                  <SkeletonElement type={'title adMob'} size={3}/>
                   <SkeletonElement type={'title adMob'} size={3}/>
                   <SkeletonElement type={'title adMob'} size={3}/>
                   <SkeletonElement type={'title adMob'} size={3}/>
                   <SkeletonElement type={'title adMob'} size={3}/>
                   <SkeletonElement type={'title adMob'} size={3}/>
                   <SkeletonElement type={'title adMob'} size={3}/>
                   <SkeletonElement type={'title adMob'} size={3}/>
                   <SkeletonElement type={'title adMob'} size={3}/>
                   <SkeletonElement type={'title adMob'} size={3}/>
                   <SkeletonElement type={'title adMob'} size={3}/>
                  </div>}
              </CardBody>
            </Card>
            <Modal
                isOpen={modalcategory}
                toggle={toggleCategory}
                className={props.className}
              >
                <ModalHeader toggle={toggleCategory} tag="h4">
                  Add AdMobs
                        </ModalHeader>
                <ModalBody>
                  <AvForm
                    onValidSubmit={handleValidEventSubmitcategory}
                  >
                    <Row form>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="adMobType"
                          label="Ad Mob Type"
                          type="text"
                          errorMessage="Invalid adMobType"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.adMobType
                            :event.adMobType
                              ? event.adMobType
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="id"
                          label="Ad Mob Id"
                          type="text"
                          disabled={!isCreate}
                          errorMessage="Invalid Ad Mob Id"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.admob_id
                            :event.id
                              ? event.id
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-right">
                          <button
                            type="button"
                            className="btn btn-light me-2"
                            onClick={toggleCategory}
                          >
                            Close
                                  </button>
                          <button
                            type="submit"
                            className="btn btn-success save-event"
                          >
                            Save
                                  </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
          </Col>
        </Row>

       
      </div>

    </React.Fragment>
  )
}

export default AdMobs
