import { db } from '../../Firebase/FIREBASE_CONFIG'

const addnewVisibleCategories = (data) => {
    db.collection('visible-categories').add(data);
}
export const updateVisibleCategorie = (data) => {
    db.collection('visible-categories').doc(data.id).set(data);
}
export const deleteVisibleCategorie = (docid) => {

    if (ConfirmDelete()) {
        db.collection("visible-categories").doc(docid).delete();
    }
    //
}

export const ConfirmDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    return confirm("Are you sure you want to delete?");
}
export const columns = [{
        label: "Group",
        field: "group",
        sort: "asc",
        width: 270,
    },
    {
        label: "Link",
        field: "link",
        sort: "asc",
        width: 200,
    },
    {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 100,
    },
    {
        label: "Rank",
        field: "rank",
        sort: "asc",
        width: 150,
    },
    {
        label: "Slug",
        field: "slug",
        sort: "asc",
        width: 150,
    },
    {
        label: "Visible",
        field: "visible",
        sort: "asc",
        width: 150,
    },
    {
        label: "Action",
        field: "action",
        width: 100,
    },
]
export default addnewVisibleCategories