/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react"
import { MDBDataTable,MDBRow,MDBBtn,MDBIcon } from "mdbreact"
import { Row, Col, Card, Button,
    
  Modal,
  ModalBody,
  Progress,
  ModalHeader,
  CardBody, CardTitle, CardSubtitle } from "reactstrap"
  import { AvField, AvForm } from "availity-reactstrap-validation"
  import addnewAds,{deleteAd,updateAd, columns} from "./common"
  import {db,check_login} from '../../Firebase/FIREBASE_CONFIG'
//Import Breadcrumb
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Tables/datatables.scss"
import './comment.css'
import SkeletonElement from "../../skeletons/SkeletonElement"
import { useRef } from "react"
const Comments  = props => {
  
    check_login() 
    const [modalcategory,setModalcategory]=useState(false)
    const [subscriberData,setSubscriberData]=useState(false)
    const [event, setEvent] = useState({})
    const [emptyComment,setEmptyComment]=useState(false)
    const [hashedPassword, setHashedPassword]=useState()
    const [tableData, setTableData] = useState([])
    const [isCreate, setisCreate] = useState()
    const getComments=()=>{
     
      db.collection('comments')
      .get()
      .then(querySnapshot => {
        const updatedSubscriptionItems = 
                    querySnapshot.docs.map((docSnapshot) => ({
                        sub_id:docSnapshot.id,
                      fullname: docSnapshot.data().fullname,
                      email: docSnapshot.data().email,
                      postId: docSnapshot.data().postId,
                      commentmessage: docSnapshot.data().message,
                      messagevisible: docSnapshot.data().visible,
                      created: docSnapshot.data().created?.toDate()?.toDateString()
                    }))
                    updateAdMobs(updatedSubscriptionItems)
                    setTimeout(() => {
                      setEmptyComment(true)
              }, 4000);
      });
      
     
    }
    const getArticles=(articleId)=>{
      return db.collection('articles').doc(articleId).get()
      .then(querySnapshot => {
        const item={
          slug: querySnapshot.data().slug,
          title: querySnapshot.data().title,
        }
        return item
      });
    }
    const updateAdMobs=(info)=>{
      let userData = [];
      info.map((item, index) => {
        getArticles(item.postId).
        then(article=>{
        item.id = (
          <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.name}</div>
        );
        item.postId=(
          <div style={{ fontWeight: "bold"}}>
          <a target="_blank" rel="noreferrer" href={"https://addisfortune.news/"+article.slug}>{article.title}</a></div>
        )
        item.action = (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
             <div className="uil-edit-alt"
             onClick={(event => toggleCategory(event,false,item))}><i className="mdi mdi-square-edit-outline"></i></div>
             <div className="uil-trash-alt"
             onClick={(event => delete_adMob(event,item.sub_id))}><i className="mdi mdi-trash-can"></i></div>
          </div>
        );
        userData.push(item);
      });
      
    })
     // console.log(userData)
      setTableData(userData)
    }
    useEffect(() => {
        getComments()
    },[]);
    const data={columns:columns,rows:tableData}
    const toggleCategory = (event,iscreate,item) => {
      event.preventDefault()
      if(iscreate){
        setSubscriberData()
        setisCreate(iscreate)
        setModalcategory(!modalcategory)
      }else{
        setSubscriberData(item)
        setisCreate(iscreate)
        setModalcategory(!modalcategory)
      }
       
      };
      const handleValidEventSubmitcategory = (event, values) => {
        const visible=values["messagevisible"]?true:false;
        const createdD=firebase.firestore.Timestamp.fromDate(new Date(subscriberData.created))
        const newdata = {
          id: subscriberData['sub_id'],
          fullname: values["fullname"],
          email: values["email"],
          message: values["commentmessage"],
          postId: subscriberData.postId,
          created:createdD,
          visible: visible,
        }
        // eslint-disable-next-line no-lone-blocks
        // save new event
        // console.log(newdata)
        updateAd(newdata)
        getComments()
        toggleCategory(event)
      }
      const delete_adMob=(event,id)=>{
        event.preventDefault();
       deleteAd(id)
       getComments()
      }
    return (
      <React.Fragment>
        <div className="page-content">
  
          <Breadcrumbs title="Comment" breadcrumbItem="Comment" />
  
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Comment Datatable </CardTitle>
                    
                        {tableData.length?<MDBDataTable striped
                          hover check responsive bordered data={data} />:
                  <div className="">
                    {emptyComment?<h2 style={{textAlign: "center"}}>No comments to moderate.</h2>:(<><SkeletonElement type={'title ads'} size={6}/>
                     <SkeletonElement type={'title ads'} size={6}/>
                     <SkeletonElement type={'title ads'} size={6}/>
                     <SkeletonElement type={'title ads'} size={6}/>
                     <SkeletonElement type={'title ads'} size={6}/>
                     <SkeletonElement type={'title ads'} size={6}/>
                     <SkeletonElement type={'title ads'} size={6}/>
                     <SkeletonElement type={'title ads'} size={6}/>
                     <SkeletonElement type={'title ads'} size={6}/>
                     <SkeletonElement type={'title ads'} size={6}/>
                     <SkeletonElement type={'title ads'} size={6}/></>)}
                    
                    </div>}
                </CardBody>
              </Card>
              <Modal
                  isOpen={modalcategory}
                  toggle={toggleCategory}
                  className={props.className}
                >
                  <ModalHeader toggle={toggleCategory} tag="h4">
                    Add AdMobs
                          </ModalHeader>
                  <ModalBody>
                    <AvForm
                      onValidSubmit={handleValidEventSubmitcategory}
                    >
                      <Row form>
                        <Col className="col-12 mb-3">
                          <AvField
                            name="fullname"
                            label="Full Name"
                            type="text"
                            errorMessage="Full name can't be empty"
                            validate={{
                              required: { value: true },
                            }}
                            value={
                              subscriberData 
                              ? subscriberData.fullname
                              :event.fullname
                                ? event.fullname
                                : ""
                            }
                          />
                        </Col>
                        <Col className="col-12 mb-3">
                          <AvField
                            name="email"
                            label="Email"
                            type="text"
                            disabled={!isCreate}
                            errorMessage="Email can't be empty"
                            validate={{
                              required: { value: true },
                            }}
                            value={
                              subscriberData 
                              ? subscriberData.email
                              :event.email
                                ? event.email
                                : ""
                            }
                          />
                        </Col>
                        <Col className="col-12 mb-3">
                          <AvField
                            name="commentmessage"
                            label="Message"
                            type="textarea"
                            errorMessage="Message can't be Empty"
                            validate={{
                              required: { value: true },
                            }}
                            value={
                              subscriberData 
                              ? subscriberData.commentmessage
                              :event.commentmessage
                                ? event.commentmessage
                                : ""
                            }
                          />
                        </Col>
                        <Col className="col-12 mb-3">
                        <AvField
                          type="select"
                          name="messagevisible"
                          label="Choose Message Status"
                          errorMessage="Select status"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.messagevisible
                             :  event.messagevisible
                             ? event.messagevisible
                              : ""
                          }
                        >
                          <option value="">Select Status</option>
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </AvField>
                      </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="text-right">
                            <button
                              type="button"
                              className="btn btn-light me-2"
                              onClick={toggleCategory}
                            >
                              Close
                                    </button>
                            <button
                              type="submit"
                              className="btn btn-success save-event"
                            >
                              Save
                                    </button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  </ModalBody>
                </Modal>
            </Col>
          </Row>
  
         
        </div>
  
      </React.Fragment>
    )
  }
  
  export default Comments
  