import { db } from '../../Firebase/FIREBASE_CONFIG'

const addnewAds = (data) => {
    db.collection('ads').add(data);
}
export const updateAd = (data) => {
    db.collection('ads').doc(data.id).set(data);
}
export const deleteAd = (docid) => {

    if (ConfirmDelete()) {
        db.collection("ads").doc(docid).delete();
    }
    //
}

export const ConfirmDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    return confirm("Are you sure you want to delete?");
}
export const columns = [{
            label: "Client Name",
            field: "clientName",
            sort: "asc",
            width: 270,
        },
        {
            label: "Destination",
            field: "destination",
            sort: "asc",
            width: 200,
        },
        {
            label: "Ad Type",
            field: "adType",
            sort: "asc",
            width: 200,
        },
        {
            label: "Published Date",
            field: "publishedDate",
            sort: "asc",
            width: 200,
        },
        {
            label: "Interval",
            field: "interval",
            sort: "asc",
            width: 100,
        },
        {
            label: "Expiry Date",
            field: "expiryDate",
            sort: "asc",
            width: 150,
        },
        {
            label: "Active",
            field: "active",
            sort: "asc",
            width: 150,
        },
        {
            label: "Action",
            field: "action",
            width: 100,
        },
    ]
    // eslint-disable-next-line import/no-anonymous-default-export
export default addnewAds