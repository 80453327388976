import React, { useEffect, useState } from "react"
import { MDBDataTable,MDBRow,MDBBtn,MDBIcon } from "mdbreact"
import { Row, Col, Card, Button,
    
  Modal,
  Progress,
  ModalBody,
  ModalHeader,
  CardBody, CardTitle, CardSubtitle } from "reactstrap"
  import { AvField, AvForm } from "availity-reactstrap-validation"
  import updateArticle,{deleteArticle, columns} from "./common"
  import {db,check_login} from '../../Firebase/FIREBASE_CONFIG'
//Import Breadcrumb
import firebase from 'firebase/compat/app';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Tables/datatables.scss"
import './articles.css'
import SkeletonElement from "../../skeletons/SkeletonElement"
const Articles  = props => {
  
check_login() 
const [modalcategory,setModalcategory]=useState(false)
const [subscriberData,setSubscriberData]=useState(false)
const [event, setEvent] = useState({})
const [hashedPassword, setHashedPassword]=useState()
const [tableData, setTableData] = useState([])

const getArticles=()=>{
 
  db.collection('articles')
  .orderBy("number", "desc")
  .orderBy("top", "desc")
  .orderBy("created", "desc")
  .get()
  .then(querySnapshot => {
    const updatedSubscriptionItems = 
                querySnapshot.docs.map((docSnapshot) => ({
                  sub_id:docSnapshot.id,
                  authorIds:docSnapshot.data().authorIds, 
                  authors:docSnapshot.data().authors,
                  categories:docSnapshot.data().categories,
                  categoryIds:docSnapshot.data().categoryIds,
                  content:docSnapshot.data().content,
                  draft:docSnapshot.data().draft, 
                  excerpt:docSnapshot.data().excerpt,
                  images:docSnapshot.data().images,
                  locked:docSnapshot.data().locked,
                  shareUrl:docSnapshot.data().shareUrl,
                  slug:docSnapshot.data().slug, 
                  updated:docSnapshot.data().updated,
                  title: docSnapshot.data().title,
                  created: docSnapshot.data().created,
                  number: docSnapshot.data().number,
                  mainNews: docSnapshot.data().mainNews?.toString(),
                  top: docSnapshot.data().top?.toString()}))
                updateArticles(updatedSubscriptionItems)
   
  });
  
 
}

const updateArticles=(info)=>{
  let userData = [];
  info.map((item, index) => {
    item.id = (
      <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.name}</div>
    );
    item.title=(
      <div style={{ fontWeight: "bold"}}>
      <a target="_blank" rel="noreferrer" href={"https://addisfortune.news/"+item.slug}>{item.title}</a></div>
    )
    item.action = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
         <div className="uil-edit-alt"
         onClick={(event => toggleCategory(event,item))}><i className="mdi mdi-square-edit-outline"></i></div>
         <div className="uil-trash-alt"
         onClick={(event => delete_article(event,item.sub_id))}><i className="mdi mdi-trash-can"></i></div>
      </div>
    );
    userData.push(item);
  });
  setTableData(userData)
}
useEffect(() => {
  getArticles()
},[]);
const data={columns:columns,rows:tableData}
const toggleCategory = (event,item) => {
  event.preventDefault()
    setSubscriberData(item)
    setModalcategory(!modalcategory)
   
  };
  const handleValidEventSubmitcategory = (event, values) => {
    const mainNews=values["mainNews"]==='true'?true:false;
    const top=values["top"]==='true'?true:false;
    console.log(values["created"])
    const createdD=firebase.firestore.Timestamp.fromDate(new Date(values["created"]))
    const newdata = {
      id: parseInt(values["id"]),
      title: values["title"],
      authorIds:subscriberData.authorIds, 
      authors:subscriberData.authors,
      categories:subscriberData.categories,
      categoryIds:subscriberData.categoryIds,
      content:subscriberData.content,
      draft:subscriberData.draft, 
      excerpt:subscriberData.excerpt,
      images:subscriberData.images,
      locked:subscriberData.locked,
      shareUrl:subscriberData.shareUrl,
      slug:subscriberData.slug, 
      updated:subscriberData.updated,
      mainNews: mainNews,
      top: top,
      created:createdD,
      number:parseInt(values['number'])
    }
    // save new event
    updateArticle(newdata)
    getArticles()
    toggleCategory(event)
  }
  const delete_article=(event,id)=>{
    event.preventDefault();
    deleteArticle(id)
    getArticles()
  }
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Articles" breadcrumbItem="Articles" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Articles Datatable </CardTitle>
                  
                      {tableData.length?<MDBDataTable responsive bordered data={data} />:
                <div className="">
                   <SkeletonElement type={'title article'} size={3}/>
                   <SkeletonElement type={'title article'} size={3}/>
                   <SkeletonElement type={'title article'} size={3}/>
                   <SkeletonElement type={'title article'} size={3}/>
                   <SkeletonElement type={'title article'} size={3}/>
                   <SkeletonElement type={'title article'} size={3}/>
                   <SkeletonElement type={'title article'} size={3}/>
                   <SkeletonElement type={'title article'} size={3}/>
                   <SkeletonElement type={'title article'} size={3}/>
                   <SkeletonElement type={'title article'} size={3}/>
                   <SkeletonElement type={'title article'} size={3}/>
                  </div>}
              </CardBody>
            </Card>
            <Modal
                isOpen={modalcategory}
                toggle={toggleCategory}
                className={props.className}
              >
                <ModalHeader toggle={toggleCategory} tag="h4">
                  Add Article
                        </ModalHeader>
                <ModalBody>
                  <AvForm
                    onValidSubmit={handleValidEventSubmitcategory}
                  >
                    <Row form>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="id"
                          type="hidden"
                          errorMessage="Invalid Id"
                          validate={{
                            required: { value: true },
                          }}
                          value={ subscriberData 
                            ? subscriberData.sub_id
                            :event.id
                              ? event.id
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="title"
                          label="Title"
                          type="text"
                          errorMessage="Invalid Title"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.title
                            :event.title
                              ? event.title
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          type="select"
                          name="mainNews"
                          label="Choose Article Status"
                          errorMessage="Select Article Status"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.mainNews:event.mainNews ? event.mainNews : ""
                          }
                        >
                          <option value="bg-danger">Main News</option>
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </AvField>
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          type="select"
                          name="top"
                          label="Choose Article Position"
                          errorMessage="Select Article Position"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.top:event.top ? event.top : ""
                          }
                        >
                          <option value="bg-danger">Top</option>
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </AvField>
                      </Col>
                      
                      <Col className="col-12 mb-3">
                        <AvField
                          name="created"
                          label="Created"
                          type="date"
                          errorMessage="Invalid created Date"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.created
                            :event.created
                              ? event.created
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="number"
                          label="Number"
                          type="Number"
                          errorMessage="Invalid number Date"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.number
                            :event.number
                              ? event.number
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-right">
                          <button
                            type="button"
                            className="btn btn-light me-2"
                            onClick={toggleCategory}
                          >
                            Close
                                  </button>
                          <button
                            type="submit"
                            className="btn btn-success save-event"
                          >
                            Save
                                  </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
          </Col>
        </Row>

       
      </div>

    </React.Fragment>
  )
}

export default Articles
