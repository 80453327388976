import { db } from '../../Firebase/FIREBASE_CONFIG'

const addnewSubscriber = (data) => {
    db.collection('subscriptions').doc(data.id).set(data);
}
export const deleteSubscriper = (docid) => {

    if (ConfirmDelete()) {
        db.collection("subscriptions").doc(docid).delete();
    }
    //
}

export const ConfirmDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    return confirm("Are you sure you want to delete?");
}
export const columns = [{
        label: "ID",
        field: "sub_id",
        sort: "asc",
        width: 270,
    }, {
        label: "First Name",
        field: "firstName",
        sort: "asc",
        width: 270,
    },
    {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
    },
    {
        label: "Last Name",
        field: "lastName",
        sort: "asc",
        width: 100,
    },
    {
        label: "Username",
        field: "username",
        sort: "asc",
        width: 150,
    },
    {
        label: "Active",
        field: "active",
        sort: "asc",
        width: 150,
    },
    {
        label: "Action",
        field: "action",
        width: 100,
    },
]
export default addnewSubscriber