import { db, auth } from '../../Firebase/FIREBASE_CONFIG'

const addnewAdminUser = (data, authdata) => {
    db.collection('admin-users').add(data);
    signWithEmailPassword(authdata);
}
export const updateAdminUser = (data) => {
    db.collection('admin-users').doc(data.id).set(data);
    forgetPassword(data.email)
}
const signWithEmailPassword = (authdata) => {
    auth.createUserWithEmailAndPassword(authdata.email, authdata.password)
        .then((auth) => {
            //successfully created a new user with email and password
            console.log(auth)
        }).catch(error => alert(error.message))
}
const forgetPassword = email => {
    return new Promise((resolve, reject) => {
        auth
            .sendPasswordResetEmail(email, {
                url: window.location.protocol + "//" + window.location.host + "/login",
            })
            .then(() => {
                resolve(true)
            })
            .catch(error => {
                reject(this._handleError(error))
            })
    })
}
export const deleteAdmob = (docid) => {
    //auth().signInWithEmailAndPassword(user.email, password).then((user) => {
    //firebase.database().ref('/users').child(user.uid).remove();
    //return user.delete();
    //})
    if (ConfirmDelete()) {
        db.collection("ad-mobs").doc(docid).delete();
    }
    //
}

export const ConfirmDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    return confirm("Are you sure you want to delete?");
}
export const columns = [{
        label: "Username",
        field: "username",
        sort: "asc",
        width: 270,
    },
    {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
    },
    {
        label: "Action",
        field: "action",
        width: 100,
    },
]
export default addnewAdminUser