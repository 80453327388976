import { db } from '../../Firebase/FIREBASE_CONFIG'

const addnewAdMobs = (data) => {
    db.collection('ad-mobs').doc(data.id).set(data);
}
export const deleteAdmob = (docid) => {

    if (ConfirmDelete()) {
        db.collection("ad-mobs").doc(docid).delete();
    }
    //
}

export const ConfirmDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    return confirm("Are you sure you want to delete?");
}
export const columns = [{
        label: "Ad Mob Type",
        field: "adMobType",
        sort: "asc",
        width: 270,
    },
    {
        label: "Ad Mob Id",
        field: "admob_id",
        sort: "asc",
        width: 200,
    },
    {
        label: "Action",
        field: "action",
        width: 100,
    },
]
export default addnewAdMobs