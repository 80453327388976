import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { initFirebaseBackend, getFirebaseBackend } from "../../../helpers/firebase_helper"
import { firebaseConfig } from "../../../Firebase/FIREBASE_CONFIG"
import {
    postSocialLogin,
} from "../../../helpers/fakebackend_helper"
initFirebaseBackend(firebaseConfig)
const fireBaseBackend = getFirebaseBackend()
    // eslint-disable-next-line react-hooks/rules-of-hooks


function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(
            fireBaseBackend.loginUser,
            user.email,
            user.password
        )
        yield put(loginSuccess(response))
        localStorage.setItem("loggedInUser", JSON.stringify(response))
        history.push("/dashboard")
    } catch (error) {
        yield put(apiError(error))
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem("authUser")
        localStorage.removeItem("loggedInUser")
        const response = yield call(fireBaseBackend.logout)
        yield put(logoutUserSuccess(response))
        history.push("/login")
    } catch (error) {
        yield put(apiError(error))
    }
}

function* socialLogin({ payload: { data, history, type } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const fireBaseBackend = getFirebaseBackend()
            const response = yield call(
                fireBaseBackend.socialLoginUser,
                data,
                type,
            )
            localStorage.setItem("authUser", JSON.stringify(response))
            yield put(loginSuccess(response))
        } else {
            const response = yield call(postSocialLogin, data)
            localStorage.setItem("authUser", JSON.stringify(response))
            yield put(loginSuccess(response))
        }
        history.push("/dashboard")
    } catch (error) {
        yield put(apiError(error))
    }
}

function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser)
    yield takeLatest(SOCIAL_LOGIN, socialLogin)
    yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga