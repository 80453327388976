import { db } from '../../Firebase/FIREBASE_CONFIG'

const addnewAds = (data) => {
    db.collection('comments').add(data);
}
export const updateAd = (data) => {
    console.log(data.id)
    db.collection('comments').doc(data.id).set(data);
}
export const deleteAd = (docid) => {

    if (ConfirmDelete()) {
        db.collection("comments").doc(docid).delete();
    }
    //
}

export const ConfirmDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    return confirm("Are you sure you want to delete?");
}
export const columns = [{
            label: "Full Name",
            field: "fullname",
            sort: "asc",
            width: 270,
        },
        {
            label: "Email",
            field: "email",
            sort: "asc",
            width: 200,
        },
        {
            label: "Message",
            field: "commentmessage",
            sort: "asc",
            width: 200,
        },
        {
            label: "Article",
            field: "postId",
            sort: "asc",
            width: 200,
        },
        {
            label: "Visible",
            field: "messagevisible",
            sort: "asc",
            width: 100,
        },
        {
            label: "Created",
            field: "created",
            sort: "asc",
            width: 150,
        },
        {
            label: "Action",
            field: "action",
            width: 100,
        },
    ]
    // eslint-disable-next-line import/no-anonymous-default-export
export default addnewAds