/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react"
import { MDBDataTable,MDBRow,MDBBtn,MDBIcon } from "mdbreact"
import { Row, Col, Card, Button,
    
  Modal,
  Progress,
  ModalBody,
  ModalHeader,
  CardBody, CardTitle, CardSubtitle } from "reactstrap"
  import { AvField, AvForm } from "availity-reactstrap-validation"
  import addnewVisibleCategories,{deleteVisibleCategorie,updateVisibleCategorie, columns} from "./common"
  import {db,check_login} from '../../Firebase/FIREBASE_CONFIG'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Tables/datatables.scss"
import './visibleCate.css'
import SkeletonElement from "../../skeletons/SkeletonElement"
const VisibleCategories  = props => {
  
   
check_login() 
const [modalcategory,setModalcategory]=useState(false)
const [subscriberData,setSubscriberData]=useState(false)
const [event, setEvent] = useState({})
const [hashedPassword, setHashedPassword]=useState()
const [tableData, setTableData] = useState([])
const [isCreate, setisCreate] = useState()
const getVisibleCategories=()=>{
 
  db.collection('visible-categories')
  .get()
  .then(querySnapshot => {
    const updatedSubscriptionItems = 
                querySnapshot.docs.map((docSnapshot) => ({
                  sub_id:docSnapshot.id,
                  group: docSnapshot.data().group,
                  link: docSnapshot.data().link,
                  name: docSnapshot.data().name,
                  rank: docSnapshot.data().rank,
                  slug: docSnapshot.data().slug,
                  visible: docSnapshot.data().visible?.toString()}))
                updateVisibleCategories(updatedSubscriptionItems)
   
  });
  
 
}

const updateVisibleCategories=(info)=>{
  let userData = [];
  info.map((item, index) => {
    item.id = (
      <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.name}</div>
    );
    item.action = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
         <div className="uil-edit-alt"
         onClick={(event => toggleCategory(event,false,item))}><i className="mdi mdi-square-edit-outline"></i></div>
         <div className="uil-trash-alt"
         onClick={(event => delete_cate(event,item.sub_id))}><i className="mdi mdi-trash-can"></i></div>
      </div>
    );
    userData.push(item);
  });
  setTableData(userData)
}
useEffect(() => {
  getVisibleCategories()
},[]);
const data={columns:columns,rows:tableData}
const toggleCategory = (event,iscreate,item) => {
  event.preventDefault()
  if(iscreate){
    setSubscriberData()
    setisCreate(iscreate)
    setModalcategory(!modalcategory)
  }else{
    setSubscriberData(item)
    setisCreate(iscreate)
    setModalcategory(!modalcategory)
  }
   
  };
  const handleValidEventSubmitcategory = (event, values) => {
    const visible=values["visible"]==='true'?true:false;
    const newdata = {
      group: parseInt(values["group"]),
      link: values["link"],
      name: values["name"],
      rank: parseInt(values["rank"]),
      slug: values["slug"],
      visible: visible
    }
    {!isCreate && (newdata['id']=subscriberData['sub_id'])}
    // save new event
    {!isCreate && updateVisibleCategorie(newdata)}
    //addnewAds(newdata)
    {isCreate && addnewVisibleCategories(newdata)}
    // save new event
    getVisibleCategories()
    toggleCategory(event)
  }
  const delete_cate=(event,id)=>{
    event.preventDefault();
    deleteVisibleCategorie(id)
    getVisibleCategories()
  }
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Categories" breadcrumbItem="Visible" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Visible Categories Datatable </CardTitle>
                  <div className="subscriber__button">
                      <Button
                        color="primary"
                        onClick={(event => toggleCategory(event,true))}
                        className="btn font-16 btn-primary w-100"
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                       Create Visible Categories
                      </Button></div>
                  
                      {tableData.length?<MDBDataTable responsive bordered data={data} />:
                <div className="">
                   <SkeletonElement type={'title visible_cate'} size={7}/>
                   <SkeletonElement type={'title visible_cate'} size={7}/>
                   <SkeletonElement type={'title visible_cate'} size={7}/>
                   <SkeletonElement type={'title visible_cate'} size={7}/>
                   <SkeletonElement type={'title visible_cate'} size={7}/>
                   <SkeletonElement type={'title visible_cate'} size={7}/>
                   <SkeletonElement type={'title visible_cate'} size={7}/>
                   <SkeletonElement type={'title visible_cate'} size={7}/>
                   <SkeletonElement type={'title visible_cate'} size={7}/>
                   <SkeletonElement type={'title visible_cate'} size={7}/>
                   <SkeletonElement type={'title visible_cate'} size={7}/>
                  </div>}
              </CardBody>
            </Card>
            <Modal
                isOpen={modalcategory}
                toggle={toggleCategory}
                className={props.className}
              >
                <ModalHeader toggle={toggleCategory} tag="h4">
                  Add a Subscriber
                        </ModalHeader>
                <ModalBody>
                  <AvForm
                    onValidSubmit={handleValidEventSubmitcategory}
                  >
                    <Row form>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="group"
                          label="Group"
                          type="text"
                          errorMessage="Invalid group"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.group
                            :event.group
                              ? event.group
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="link"
                          label="Link"
                          type="text"
                          errorMessage="Invalid link"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.link
                            :event.link
                              ? event.link
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="name"
                          label="Name"
                          type="text"
                          errorMessage="Invalid name"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.name
                            :event.name
                              ? event.name
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="rank"
                          label="Rank"
                          type="text"
                          errorMessage="Invalid rank"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.rank
                            :event.rank
                              ? event.rank
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="slug"
                          label="Slug"
                          type="text"
                          errorMessage="Invalid slug"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.slug
                            :event.slug
                              ? event.slug
                              : ""
                          }
                        />
                      </Col>

                      <Col className="col-12 mb-3">
                        <AvField
                          type="select"
                          name="visible"
                          label="Choose Category Status"
                          errorMessage="Select status"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.visible
                            :event.visible ? event.visible : ""
                          }
                        >
                          <option value="">Select Status</option>
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </AvField>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-right">
                          <button
                            type="button"
                            className="btn btn-light me-2"
                            onClick={toggleCategory}
                          >
                            Close
                                  </button>
                          <button
                            type="submit"
                            className="btn btn-success save-event"
                          >
                            Save
                                  </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
          </Col>
        </Row>

       
      </div>

    </React.Fragment>
  )
}

export default VisibleCategories
