import React, { useEffect, useState } from "react"
import { MDBDataTable,MDBRow,MDBBtn,MDBIcon } from "mdbreact"
import { Row, Col, Card, Button,   
  Modal,
  ModalBody,
  ModalHeader,Progress,
  CardBody, CardTitle, CardSubtitle } from "reactstrap"
  import { AvField, AvForm } from "availity-reactstrap-validation"
import addnewSubscriber,{deleteSubscriper, columns} from "./common"
import {db,check_login} from '../../Firebase/FIREBASE_CONFIG'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Tables/datatables.scss"
import './subscription.css'
import SkeletonElement from "../../skeletons/SkeletonElement"

const Subscription  = props => {

check_login() 
const [modalcategory,setModalcategory]=useState(false)
const [subscriberData,setSubscriberData]=useState(false)
const [event, setEvent] = useState({})
const [hashedPassword, setHashedPassword]=useState()
const [tableData, setTableData] = useState([])
const [isCreate, setisCreate] = useState()
const getSubscription=()=>{
 
  db.collection('subscriptions')
  .get()
  .then(querySnapshot => {
    const updatedSubscriptionItems = 
                querySnapshot.docs.map((docSnapshot) => ({
                  sub_id:docSnapshot.id,
                  firstName: docSnapshot.data().firstName,
                  lastName: docSnapshot.data().lastName,
                  email: docSnapshot.data().email,
                  username: docSnapshot.data().username,
                  active: docSnapshot.data().active?.toString(),
                  password: docSnapshot.data().password}))
                updateSubscription(updatedSubscriptionItems)
   
  });
  
 
}

const updateSubscription=(info)=>{
  let userData = [];
  info.map((item, index) => {
    item.id = (
      <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.name}</div>
    );
    item.action = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
         <div className="uil-edit-alt"
         onClick={(event => toggleCategory(event,false,item))}><i className="mdi mdi-square-edit-outline"></i></div>
         <div className="uil-trash-alt"
         onClick={(event => delete_sub(event,item.sub_id))}><i className="mdi mdi-trash-can"></i></div>
      </div>
    );
    userData.push(item);
  });
  setTableData(userData)
}
useEffect(() => {
  getSubscription()
},[]);
const delete_sub=(event,id)=>{
  event.preventDefault();
  deleteSubscriper(id)
  getSubscription()
}
const data={columns:columns,rows:tableData}
const toggleCategory = (event,iscreate,item) => {
  event.preventDefault()
  if(iscreate){
    setSubscriberData()
    setisCreate(iscreate)
    setModalcategory(!modalcategory)
  }else{
    setSubscriberData(item)
    setisCreate(iscreate)
    setModalcategory(!modalcategory)
  }
   
  };
  const handleValidEventSubmitcategory =async  (event, values) => {
    const active=values["active"]==='true'?true:false;
    let storeProducts =   await fetch(`https://addisfortune.news/mobile_api/wp_encrypt_password.php?password=${data}`).then(res => res.json()).then((responseJson) => responseJson.encrypted);
    setHashedPassword(storeProducts)
    console.log(storeProducts)
    const newdata = {
      id: values["id"],
      firstName: values["firstName"],
      lastName: values["lastName"],
      email: values["email"],
      username: values["username"],
      active: active,
      password: storeProducts
    }
    // save new event
    console.log(newdata)
    addnewSubscriber(newdata)
    getSubscription()
    toggleCategory(event)
  }
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Subscription" breadcrumbItem="Subscriptions" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Subscription Datatable </CardTitle>
                  <div className="subscriber__button">
                      <Button
                        color="primary"
                        
                        onClick={(event => toggleCategory(event,true))}
                        className="btn font-16 btn-primary w-100"
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                       Add subscriber
                      </Button></div>
                  
                {tableData.length?<MDBDataTable responsive bordered data={data} />:
                <div className="">
                    <SkeletonElement type={'title subs'} size={7}/>
                   <SkeletonElement type={'title subs'} size={7}/>
                   <SkeletonElement type={'title subs'} size={7}/>
                   <SkeletonElement type={'title subs'} size={7}/>
                   <SkeletonElement type={'title subs'} size={7}/>
                   <SkeletonElement type={'title subs'} size={7}/>
                   <SkeletonElement type={'title subs'} size={7}/>
                   <SkeletonElement type={'title subs'} size={7}/>
                   <SkeletonElement type={'title subs'} size={7}/>
                   <SkeletonElement type={'title subs'} size={7}/>
                   <SkeletonElement type={'title subs'} size={7}/>
                  </div>}
              </CardBody>
            </Card>
            <Modal
                isOpen={modalcategory}
                toggle={toggleCategory}
                className={props.className}
              >
                <ModalHeader toggle={toggleCategory} tag="h4">
                  Add a Subscriber
                        </ModalHeader>
                <ModalBody>
                  <AvForm
                    onValidSubmit={handleValidEventSubmitcategory}
                  >
                    <Row form>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="id"
                          label="Id"
                          type="text"
                          disabled={!isCreate}
                          errorMessage="Invalid Id"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.sub_id
                            : event.id
                              ? event.id
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="firstName"
                          label="First Name"
                          type="text"
                          errorMessage="Invalid first name"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.firstName
                             :  event.firstName
                             ? event.firstName
                              : ""
                              
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="lastName"
                          label="Last Name"
                          type="text"
                          errorMessage="Invalid last name"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.lastName
                             :  event.lastName
                             ? event.lastName
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          type="email"
                          errorMessage="Invalid Email"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.email
                             :  event.email
                             ? event.email
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="username"
                          label="Username"
                          type="text"
                          errorMessage="Invalid username"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.username
                             :  event.username
                             ? event.username
                              : ""
                          }
                        />
                      </Col>

                      <Col className="col-12 mb-3">
                        <AvField
                          type="select"
                          name="active"
                          label="Choose Account Status"
                          errorMessage="Select status"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.active
                             :  event.active
                             ? event.active
                              : ""
                          }
                        >
                          <option value="">Select Status</option>
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </AvField>
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          errorMessage="Invalid Password"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.password
                             :  event.password
                             ? event.password
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-right">
                          <button
                            type="button"
                            className="btn btn-light me-2"
                            onClick={toggleCategory}
                          >
                            Close
                                  </button>
                          <button
                            type="submit"
                            className="btn btn-success save-event"
                          >
                            Save
                                  </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
          </Col>
        </Row>

       
      </div>

    </React.Fragment>
  )
}

export default Subscription
