import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"

import { Switch, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import {db,check_login,auth} from "./Firebase/FIREBASE_CONFIG"
// Import scss
import "./assets/scss/theme.scss"


import fakeBackend from "./helpers/AuthType/fakeBackend"
import {isUserAuthenticated,getLoggedInUser} from "./helpers/fakebackend_helper"

// Activating fake backend
//fakeBackend()

const App = props => {
  const[isAuthenticated,setAuthenticated]=useState()
  function getLayout() {
    let layoutCls = VerticalLayout

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }
  useEffect(()=>{
    auth.onAuthStateChanged(authUser=>{
      if(authUser){
        console.log(authUser)
        setAuthenticated(false);
      }else{
        setAuthenticated(true);
      }
    })
     // eslint-disable-next-line react-hooks/exhaustive-deps
    // setAuthenticated(isUserAuthenticated()?true:false);
  },[])
  console.log(isAuthenticated)
  const Layout = getLayout()
  return (
    <React.Fragment>
      <Router>

        <Switch>
        {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
          
          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={isAuthenticated}
              exact
            />
          ))}
        </Switch>

      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)