import { db } from '../../Firebase/FIREBASE_CONFIG'


const updateArticle = (data) => {
    db.collection('articles').doc(data.id.toString()).set(data);
}
export const deleteArticle = (docid) => {

    if (ConfirmDelete()) {
        db.collection("articles").doc(docid).delete();
    }
    //
}

export const ConfirmDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    return confirm("Are you sure you want to delete?");
}
export const columns = [{
        label: "Title",
        field: "title",
        sort: "asc",
        width: 270,
    },
    {
        label: "Main News",
        field: "mainNews",
        sort: "asc",
        width: 100,
    },
    {
        label: "Top",
        field: "top",
        sort: "asc",
        width: 150,
    },
    {
        label: "Action",
        field: "action",
        width: 100,
    },
]
export default updateArticle