import PropTypes from "prop-types"
import React, { useEffect, useRef , useCallback, useState} from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  const [loggedInUser, setLoggedInUser] = useState([]);
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }
    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag
        const parent3 = parent2.parentElement // li tag
        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }, []);
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname
    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
        const userItems = JSON.parse(localStorage.getItem('loggedInUser'));
        if (userItems) {
          setLoggedInUser(userItems);
        }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname, activateParentDropdown])
  useEffect(() => {
    ref.current.recalculate()
  }, []);
  const scrollElement = (item) => {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }
  

  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Menu</li>
            <li style={loggedInUser?.email==='socialmedia@addisfortune.net'? { display: 'none' } : { display: 'block' }}>
              <Link to="/dashboard" className=" waves-effect">
              <i className="mdi mdi-airplay"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            <li style={loggedInUser?.email==='socialmedia@addisfortune.net'? { display: 'none' } : { display: 'block' }}>
              <Link to="/subscriptions" className=" waves-effect">
              <i className="mdi mdi-account-multiple-outline"></i>
                <span>Subscriptions</span>
              </Link>
            </li>
            <li>
              <Link to="/ads" className=" waves-effect">
              <i className="mdi mdi-tag-plus-outline"></i>
                <span>Ads</span>
              </Link>
            </li>
            <li style={loggedInUser?.email==='socialmedia@addisfortune.net' && loggedInUser?.email==='newmedia@addisfortune.net'? { display: 'none' } : { display: 'block' }}>
              <Link to="/articles" className=" waves-effect">
                <i className="mdi mdi-content-save-edit-outline"></i>
                <span>Articles</span>
              </Link>
            </li>
            <li style={loggedInUser?.email==='socialmedia@addisfortune.net' && loggedInUser?.email==='newmedia@addisfortune.net'? { display: 'none' } : { display: 'block' }}>
              <Link to="/home-categories" className=" waves-effect">
              <i className="fas fa-home"></i>
                <span>Home-categories</span>
              </Link>
            </li>
            <li style={loggedInUser?.email==='socialmedia@addisfortune.net' && loggedInUser?.email==='newmedia@addisfortune.net'? { display: 'none' } : { display: 'block' }}>
              <Link to="/ad-mobs" className=" waves-effect">
              <i className="fas fa-ad"></i>
                <span>Ad-mobs</span>
              </Link>
            </li>
            <li style={loggedInUser?.email==='socialmedia@addisfortune.net' && loggedInUser?.email==='newmedia@addisfortune.net'? { display: 'none' } : { display: 'block' }}>
              <Link to="/visible-categories" className=" waves-effect">
                <i className="mdi mdi-apps"></i>
                <span>Visible-categories</span>
              </Link>
            </li>
            <li>
              <Link to="/comments" className=" waves-effect">
                <i className="mdi mdi-message"></i>
                <span>Comments</span>
              </Link>
            </li>
            <li style={loggedInUser?.email==='socialmedia@addisfortune.net' && loggedInUser?.email==='newmedia@addisfortune.net'? { display: 'none' } : { display: 'block' }}>
              <Link to="/users" className=" waves-effect">
                <i className="mdi mdi-account"></i>
                <span>Admin users</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  loginUser: PropTypes.func,
}
const mapStateToProps = state => {
  const { error } = state.Login
  const { success } = state.Login
  return { error,success }
}

export default withRouter(
  connect(mapStateToProps)(SidebarContent)
)