import { db } from '../../Firebase/FIREBASE_CONFIG'

const addnewHomeCategory = (data) => {
    db.collection('home-categories').doc(data.id.toString()).set(data);
}
export const deleteHomeCategory = (docid) => {

    if (ConfirmDelete()) {
        db.collection("home-categories").doc(docid).delete();
    }
    //
}

export const ConfirmDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    return confirm("Are you sure you want to delete?");
}
export const columns = [{
        label: "Category Type",
        field: "categoryType",
        sort: "asc",
        width: 270,
    },
    {
        label: "Content Count",
        field: "contentCount",
        sort: "asc",
        width: 200,
    },
    {
        label: "Rank",
        field: "rank",
        sort: "asc",
        width: 100,
    },
    {
        label: "show Title",
        field: "showTitle",
        sort: "asc",
        width: 150,
    },
    {
        label: "Title",
        field: "title",
        sort: "asc",
        width: 150,
    },
    {
        label: "Visible",
        field: "visible",
        sort: "asc",
        width: 150,
    },
    {
        label: "Action",
        field: "action",
        width: 100,
    },
]
export default addnewHomeCategory