import React from 'react'
import './Skeleton.css'
function SkeletonElement({type,size}) {
    const classes =`${type}`;
    const sizes=size;
    console.log(sizes)
    const getclasses=()=>{
        var rows = [];

            for (var i = 0; i < sizes; i++) {
                rows.push(<div className={classes}></div>);
            } 
            return rows;
    }
  return (
    <div className='skeleton '>
        {getclasses()}
    </div>
  )
}

export default SkeletonElement
