/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react"
import { MDBDataTable,MDBRow,MDBBtn,MDBIcon } from "mdbreact"
import { Row, Col, Card, Button,
    
  Modal,
  ModalBody,
  Progress,
  ModalHeader,
  CardBody, CardTitle, CardSubtitle } from "reactstrap"
  import { AvField, AvForm } from "availity-reactstrap-validation"
  import addnewHomeCategory,{deleteHomeCategory, columns} from "./common"
  import {db,check_login} from '../../Firebase/FIREBASE_CONFIG'
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Tables/datatables.scss"
import './homeCatagory.css'
import SkeletonElement from "../../skeletons/SkeletonElement"
const HomeCategories  = props => {
  
 
check_login() 
const [modalcategory,setModalcategory]=useState(false)
const [subscriberData,setSubscriberData]=useState(false)
const [event, setEvent] = useState({})
const [hashedPassword, setHashedPassword]=useState()
const [tableData, setTableData] = useState([])
const [isCreate, setisCreate] = useState()
const getHomeCategories=()=>{
 
  db.collection('home-categories')
  .get()
  .then(querySnapshot => {
    const updatedSubscriptionItems = 
                querySnapshot.docs.map((docSnapshot) => ({
                  sub_id:docSnapshot.id,
                  categoryType: docSnapshot.data().categoryType,
                  contentCount: docSnapshot.data().contentCount,
                  rank: docSnapshot.data().rank,
                  showTitle: docSnapshot.data().showTitle?.toString(),
                  title: docSnapshot.data().title,
                  visible: docSnapshot.data().visible?.toString()}))
                updateHomeCategories(updatedSubscriptionItems)
   
  });
  
 
}

const updateHomeCategories=(info)=>{
  let userData = [];
  info.map((item, index) => {
    item.id = (
      <div style={{ fontWeight: "bold", fontSize: "1.2em" }}>{item.name}</div>
    );
    item.action = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
         <div className="uil-edit-alt"
         onClick={(event => toggleCategory(event,false,item))}><i className="mdi mdi-square-edit-outline"></i></div>
         <div className="uil-trash-alt"
         onClick={(event => delete_cate(event,item.sub_id))}><i className="mdi mdi-trash-can"></i></div>
      </div>
    );
    userData.push(item);
  });
  setTableData(userData)
}
useEffect(() => {
  getHomeCategories()
},[]);
const data={columns:columns,rows:tableData}
const toggleCategory = (event,iscreate,item) => {
  event.preventDefault()
  if(iscreate){
    setSubscriberData()
    setisCreate(iscreate)
    setModalcategory(!modalcategory)
  }else{
    setSubscriberData(item)
    setisCreate(iscreate)
    setModalcategory(!modalcategory)
  }
   
  };
  const handleValidEventSubmitcategory = (event, values) => {
    const showTitle=values["showTitle"]==='true'?true:false;
    const visible=values["visible"]==='true'?true:false;
    const newdata = {
      id: parseInt(values["categoryid"]),
      categoryType: parseInt(values["categoryType"]),
      contentCount: parseInt(values["contentCount"]),
      rank: parseInt(values["rank"]),
      showTitle: showTitle,
      title: values["title"],
      visible: visible,
    }
    
    addnewHomeCategory(newdata)
    // save new event

    getHomeCategories()
    toggleCategory(event)
  }
  const delete_cate=(event,id)=>{
    event.preventDefault();
    deleteHomeCategory(id)
    getHomeCategories()
  }
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Categories" breadcrumbItem="Home" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Home Category Datatable </CardTitle>
                  <div className="subscriber__button">
                      <Button
                        color="primary"
                        onClick={(event => toggleCategory(event,true))}
                        className="btn font-16 btn-primary w-100"
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                       Create Home Categories
                      </Button></div>
                  
                      {tableData.length?<MDBDataTable responsive bordered data={data} />:
                <div className="">
                  <SkeletonElement type={'title home_cate'} size={7}/>
                   <SkeletonElement type={'title home_cate'} size={7}/>
                   <SkeletonElement type={'title home_cate'} size={7}/>
                   <SkeletonElement type={'title home_cate'} size={7}/>
                   <SkeletonElement type={'title home_cate'} size={7}/>
                   <SkeletonElement type={'title home_cate'} size={7}/>
                   <SkeletonElement type={'title home_cate'} size={7}/>
                   <SkeletonElement type={'title home_cate'} size={7}/>
                   <SkeletonElement type={'title home_cate'} size={7}/>
                   <SkeletonElement type={'title home_cate'} size={7}/>
                   <SkeletonElement type={'title home_cate'} size={7}/>
                  </div>}
              </CardBody>
            </Card>
            <Modal
                isOpen={modalcategory}
                toggle={toggleCategory}
                className={props.className}
              >
                <ModalHeader toggle={toggleCategory} tag="h4">
                  Add Home Categories
                        </ModalHeader>
                <ModalBody>
                  <AvForm
                    onValidSubmit={handleValidEventSubmitcategory}
                  >
                    <Row form>
                    <Col className="col-12 mb-3">
                        <AvField
                          name="categoryid"
                          label="Category Id"
                          type="text"
                          disabled={!isCreate}
                          errorMessage="Invalid Category Type"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.sub_id
                            :event.id
                              ? event.id
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="categoryType"
                          label="Category Type"
                          type="text"
                          errorMessage="Invalid Category Type"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.categoryType
                            :event.categoryType
                              ? event.categoryType
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="contentCount"
                          label="Content Count"
                          type="text"
                          errorMessage="Invalid Content Count"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.contentCount
                            :event.contentCount
                              ? event.contentCount
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="rank"
                          label="Rank"
                          type="text"
                          errorMessage="Invalid rank"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.rank
                            :event.rank
                              ? event.rank
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          type="select"
                          name="showTitle"
                          label="Choose Show Title"
                          errorMessage="Select Show Title"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.showTitle
                            :event.showTitle ? event.showTitle : ""
                          }
                        >
                          <option value="">Show Title</option>
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </AvField>
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="title"
                          label="Title"
                          type="text"
                          errorMessage="Invalid Title"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.title
                            :event.title
                              ? event.title
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          type="select"
                          name="visible"
                          label="Choose Category Status"
                          errorMessage="Select status"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.visible
                            :event.visible ? event.visible : ""
                          }
                        >
                          <option value="">Select Status</option>
                          <option value="true">True</option>
                          <option value="false">False</option>
                        </AvField>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-right">
                          <button
                            type="button"
                            className="btn btn-light me-2"
                            onClick={toggleCategory}
                          >
                            Close
                                  </button>
                          <button
                            type="submit"
                            className="btn btn-success save-event"
                          >
                            Save
                                  </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
          </Col>
        </Row>

       
      </div>

    </React.Fragment>
  )
}

export default HomeCategories
