/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react"
import { MDBDataTable,MDBRow,MDBBtn,MDBIcon } from "mdbreact"
import { Row, Col, Card, Button,
    
  Modal,
  ModalBody,
  Progress,
  ModalHeader,
  CardBody, CardTitle, CardSubtitle } from "reactstrap"
  import { AvField, AvForm } from "availity-reactstrap-validation"
  import addnewAds,{deleteAd,updateAd, columns} from "./common"
  import {db,check_login} from '../../Firebase/FIREBASE_CONFIG'
//Import Breadcrumb
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../Tables/datatables.scss"
import './ads.css'
import SkeletonElement from "../../skeletons/SkeletonElement"
const Ads  = props => {
  check_login() 
const [modalcategory,setModalcategory]=useState(false)
const [subscriberData,setSubscriberData]=useState(false)
const [event, setEvent] = useState({})
const [hashedPassword, setHashedPassword]=useState()
const [tableData, setTableData] = useState([])
const [isCreate, setisCreate] = useState()
const [resourceCounter,setResource]=useState(1)
const [resourceRows,setResourceRows] = useState([])
const getAds=()=>{
 
  db.collection('ads')
  .get()
  .then(querySnapshot => {
    const updatedSubscriptionItems = 
                querySnapshot.docs.map((docSnapshot) => ({
                  sub_id:docSnapshot.id,
                  clientName: docSnapshot.data().clientName,
                  destination: docSnapshot.data().destination,
                  adType: docSnapshot.data().adType,
                  publishedDate: docSnapshot.data().publishedDate?.toDate().toDateString(),
                  interval: docSnapshot.data().interval,
                  expiryDate: docSnapshot.data().expiryDate?.toDate().toDateString(),
                  resource: docSnapshot.data().resource,
                  resources:docSnapshot.data().resources,
                  active: docSnapshot.data().active?.toString()}))
                  console.log(updatedSubscriptionItems)
                updateAds(updatedSubscriptionItems)
   
  });
  
 
}

const updateAds=(info)=>{
  let userData = [];
  info.map((item, index) => {
    item.action = (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
         <div className="uil-edit-alt"
         onClick={(event => toggleCategory(event,false,item))}><i className="mdi mdi-square-edit-outline"></i></div>
         <div className="uil-trash-alt"
         onClick={(event => delete_ad(event,item.sub_id))}><i className="mdi mdi-trash-can"></i></div>
      </div>
    );
    userData.push(item);
  });
  setTableData(userData)
}
useEffect(() => {
  getAds()
},[]);
const data={columns:columns,rows:tableData}
const toggleCategory = (event,iscreate,item) => {
  event.preventDefault()
  if(iscreate){
    setSubscriberData()
    setisCreate(iscreate)
    setModalcategory(!modalcategory)
  }else{
    setisCreate(iscreate)
    setResourceRows([])
    setResource(1);
    setSubscriberData(item)
    const resources=item?item.resources:null;
    var rows = [];
    for(var i in resources){
      if(i>0){
        const resource_name='resources'+i;
        rows.push(input_rsource(resource_name,i,resources[i]));
       }
          }
          setResourceRows(rows)
    setModalcategory(!modalcategory)
  }
   
  };
  const input_rsource=(resource_name,id,re_data)=>{
    return(
      <div>
        <Col className="col-12 mb-3 add__resources">
                        <AvField
                          name={resource_name}
                          label={resource_name}
                          type="text"
                          errorMessage="Invalid Resources"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            re_data 
                            ? re_data
                            : event.resources
                              ? event.resources
                              : ""
                          }
                        />
                        <i onClick={(event => removeResource(event,id))} className="mdi mdi-minus-circle-outline me-1 add__icon" />
                      </Col>
      </div>
    )
  }
  const addresource=()=>{
    setResource(resourceCounter => resourceCounter + 1);
    var rows = [];
  for (var i = 1; i <= resourceCounter; i++) {
    const resource_name='resources'+i;
      rows.push(input_rsource(resource_name,i));
  
}
    
    setResourceRows(rows)
    
  }
  const removeResource=(event,resource_id)=>{
    event.preventDefault();
    setResource(resourceCounter => resourceCounter - 1);
    console.log(resourceCounter)
    const rows = resourceRows.filter((_, index) => resource_id!==index);
    setResourceRows(rows)
  }
  const handleValidEventSubmitcategory = (event, values) => {
    const active=values["active"]?true:false;
    const publishedDate=firebase.firestore.Timestamp.fromDate(new Date(values["publishedDate"]))
    const expiry_date=firebase.firestore.Timestamp.fromDate(new Date(values["expiryDate"]))
    const resources_={
      0:values["resources"]
    }
    for (var i = 1; i <= resourceCounter; i++) {
      const resource_name='resources'+i;
      if(values[resource_name]){
        resources_[i]=values[resource_name];
      }
    } 
    const newdata = {
      clientName: values["clientName"],
      destination: values["destination"],
      adType: values["adType"],
      publishedDate: publishedDate,
      interval: parseInt(values["interval"]),
      expiryDate: expiry_date,
      active: active,
      resource:  values["resource"],
      resources:resources_
    }
    // eslint-disable-next-line no-lone-blocks
    {!isCreate && (newdata['id']=subscriberData['sub_id'])}
    // save new event
    {!isCreate && updateAd(newdata)}
    //addnewAds(newdata)
    {isCreate && addnewAds(newdata)}
    getAds()
    toggleCategory(event)
  }
  const delete_ad=(event,id)=>{
    event.preventDefault();
    deleteAd(id)
    getAds()
  }
  const getDDMMYY=(pdate)=>{
    let date=new Date(pdate)
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return year  + '-' +  month+ '-' + day;
  }
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Ads" breadcrumbItem="Ads" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Ads Datatable </CardTitle>
                  <div className="subscriber__button">
                      <Button
                        color="primary"
                        onClick={(event => toggleCategory(event,true))}
                        className="btn font-16 btn-primary w-100"
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                       Create Ads
                      </Button></div>
                  
                      {tableData.length?<MDBDataTable responsive bordered data={data} />:
                <div className="">
                    <SkeletonElement type={'title ads'} size={8}/>
                   <SkeletonElement type={'title ads'} size={8}/>
                   <SkeletonElement type={'title ads'} size={8}/>
                   <SkeletonElement type={'title ads'} size={8}/>
                   <SkeletonElement type={'title ads'} size={8}/>
                   <SkeletonElement type={'title ads'} size={8}/>
                   <SkeletonElement type={'title ads'} size={8}/>
                   <SkeletonElement type={'title ads'} size={8}/>
                   <SkeletonElement type={'title ads'} size={8}/>
                   <SkeletonElement type={'title ads'} size={8}/>
                   <SkeletonElement type={'title ads'} size={8}/>
                  </div>}
              </CardBody>
            </Card>
            <Modal
                isOpen={modalcategory}
                toggle={toggleCategory}
                className={props.className}
              >
                <ModalHeader toggle={toggleCategory} tag="h4">
                  Add Ads
                        </ModalHeader>
                <ModalBody>
                  <AvForm
                    onValidSubmit={handleValidEventSubmitcategory}
                  >
                    <Row form>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="clientName"
                          label="Client Name"
                          type="text"
                          errorMessage="Invalid Client Name"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.clientName
                            :event.clientName
                              ? event.clientName
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="destination"
                          label="Destination"
                          type="text"
                          errorMessage="Invalid Destination"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.destination
                            :event.destination
                              ? event.destination
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="adType"
                          label="Ad Type"
                          type="text"
                          errorMessage="Invalid Ad Type"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.adType
                            :event.adType
                              ? event.adType
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="publishedDate"
                          label="Published Date"
                          type="date"
                          errorMessage="Invalid Published Date"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? getDDMMYY(subscriberData.publishedDate)
                            :event.publishedDate
                              ? getDDMMYY(event.publishedDate)
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="interval"
                          label="Interval"
                          type="text"
                          errorMessage="Invalid Interval"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.interval
                            :event.interval
                              ? event.interval
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="expiryDate"
                          label="Expiry Date"
                          type="date"
                          errorMessage="Invalid Expiry Date"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? getDDMMYY(subscriberData.expiryDate)
                            :event.expiryDate
                              ? getDDMMYY(event.expiryDate)
                              : ""
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          type="select"
                          name="active"
                          label="Choose Account Status"
                          errorMessage="Select status"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.active
                             :  event.active
                             ? event.active
                              : ""
                          }
                        >
                          <option value="">Select Status</option>
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </AvField>
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="resource"
                          label="Resource"
                          type="text"
                          errorMessage="Invalid Resource"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.resource
                            :event.resource
                              ? event.resource
                              : ""
                          }
                        />
                      </Col>
                      {resourceRows}
                      <Col className="col-12 mb-3 add__resources">
                        <AvField
                          name="resources"
                          label="Resources"
                          type="text"
                          errorMessage="Invalid Resources"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            subscriberData 
                            ? subscriberData.resources[0]
                            :event.resources
                              ? event.resources
                              : ""
                          }
                        />
                        <i onClick={addresource}className="mdi mdi-plus-circle-outline me-1 add__icon" />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-right">
                          <button
                            type="button"
                            className="btn btn-light me-2"
                            onClick={toggleCategory}
                          >
                            Close
                                  </button>
                          <button
                            type="submit"
                            className="btn btn-success save-event"
                          >
                            Save
                                  </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
          </Col>
        </Row>

       
      </div>

    </React.Fragment>
  )
}

export default Ads
